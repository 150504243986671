import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import Dropzone from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import history from './history';
import TextField from '@material-ui/core/TextField';
import AutoComplete from './AutoComplete';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = theme => ({
	title: {
		flexGrow: 1,
	},
	root: {
		width: '100%',
		overflowX: 'auto',
		overflowY: 'auto',
		padding: theme.spacing.unit * 3,
	},
	hidden: {
		display: 'none',
	},
	toolbar: {
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	warning: {
		color: "#bf6500",
	},
	deleteDatabase: {
		color: "#ff0000",
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	
	'@keyframes progress': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'100%': {
			backgroundPosition: '-70px 0',
		},
	},
	dropZone: {
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		backgroundColor: '#F0F0F0',
		border: 'dashed',
		borderColor: '#C8C8C8',
		cursor: 'pointer',
		boxSizing: 'border-box',
	},
	stripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	rejectStripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	dropzoneTextStyle:{
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		textAlign: 'center',
	},
	dropzoneParagraph:{
		top: 0,
	},
});

class AdminTools extends Component {
	
	constructor(props) {
		super(props);
		// console.log(">> AdminTools.constructor");
		
		this.state = {
            restoreImageConfirmationDialogOpened: false,
			updateFQDNDialogOpened: false,
			updateEmbeddingSecretKeyDialogOpened: false,
			updateDocumentMaxFileSizeMBDialogOpened: false,
			restoreConfirmationDialogOpened: false,
			importExcelConfirmationDialogOpened: false,
			pitrBackupConfirmationDialogOpened: false,
			restoreDatabaseConfirmationDialogOpened: false,
			deleteDatabaseConfirmationDialogOpened: false,
			restartPlatformConfirmationDialogOpened: false,
			updateIAMDialogOpened: false,
			fqdn: "",
			embeddingSecretKey: "",
			documentMaxFileSizeMB: 0,
			publicKey: "",
			defaultRole: "",
			externalUrl: "",
			pitrRestoreTargetTime: "",
		};
		
		this.handleDownloadBackupClick = this.handleDownloadBackupClick.bind(this);
		this.handleExcelExportClick = this.handleExcelExportClick.bind(this);
        this.handleDslExportClick = this.handleDslExportClick.bind(this);
		this.handlePitrBackupClick = this.handlePitrBackupClick.bind(this);
		this.handlePitrDeletePreviousClick = this.handlePitrDeletePreviousClick.bind(this);
		this.handlePitrExportClick = this.handlePitrExportClick.bind(this);
		this.handleRestoreDatabaseClick = this.handleRestoreDatabaseClick.bind(this);
		this.handleDeleteDatabaseClick = this.handleDeleteDatabaseClick.bind(this);
		this.handleRestartPlatformClick = this.handleRestartPlatformClick.bind(this);
		this.handleChangeFQDNClick = this.handleChangeFQDNClick.bind(this);
		this.handleChangeEmbeddingSecretKeyClick = this.handleChangeEmbeddingSecretKeyClick.bind(this);
		this.handleChangeDocumentMaxFileSizeMBClick = this.handleChangeDocumentMaxFileSizeMBClick.bind(this);
		this.handleChangeIAMClick = this.handleChangeIAMClick.bind(this);
		this.handleFileDrop = this.handleFileDrop.bind(this);
		this.handleFileDropImportExcel = this.handleFileDropImportExcel.bind(this);
        this.handleFileDropRestoreImage = this.handleFileDropRestoreImage.bind(this);
		this.handleRestore = this.handleRestore.bind(this);
		this.handleRestoreDatabase = this.handleRestoreDatabase.bind(this);
		this.handlePitrBackup = this.handlePitrBackup.bind(this);
		this.handlePitrDeletePrevious = this.handlePitrDeletePrevious.bind(this);
		this.handleChangeFQDN = this.handleChangeFQDN.bind(this);
		this.handleChangeIAM = this.handleChangeIAM.bind(this);
		this.handleChangeEmbeddingSecretKey = this.handleChangeEmbeddingSecretKey.bind(this);
		this.handleChangeDocumentMaxFileSizeMB = this.handleChangeDocumentMaxFileSizeMB.bind(this);
		this.handleImportExcel = this.handleImportExcel.bind(this);
        this.handleRestoreImage = this.handleRestoreImage.bind(this);
		this.handleDeleteDatabase = this.handleDeleteDatabase.bind(this);
		this.handleRestartPlatform = this.handleRestartPlatform.bind(this);
		this.handleChangeSelectedTab = this.handleChangeSelectedTab.bind(this);
		
		if (this.props.context.state["_AdminTool"] == null) {
			this.props.context.state["_AdminTool"] = {};
		}
	}
	
	componentDidMount() {
		this.props.context.handleDrawerClose();
		
		if (this.defaultRoleInputRef == null) {
			this.defaultRoleInputRef = React.createRef();
		}
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{version: getPlatformVersion fqdn: getFQDN {fqdn ipAddress} size: getDataVolumeSize {size used available} cpus: getCPUCount metabase: Models_MetabaseList { embeddingSecretKey } system: Models_SystemList { documentMaxFileSizeMB } checkPitrEnabled checkDatabaseBackup iam: Models_IAMList { publicKey defaultRole defaultRoleName: RoleViaDefaultRole {name} externalUrl }}", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json.errors != null) {
				document.location = '/login';
			}
			else {
				if (json.data.iam[0].defaultRole != null && this.defaultRoleInputRef != null) {
					let selectedValue = {
						value: json.data.iam[0].defaultRole, 
						label: json.data.iam[0].defaultRoleName.name,
					};
					this.defaultRoleInputRef.current.select.select.setValue(selectedValue, 'select-option');
				}
				this.setState({
					version: json.data.version,
					fqdn: json.data.fqdn.fqdn,
					embeddingSecretKey: json.data.metabase[0].embeddingSecretKey,
					documentMaxFileSizeMB: json.data.system[0].documentMaxFileSizeMB,
					publicKey: json.data.iam[0].publicKey,
					defaultRole: json.data.iam[0].defaultRole,
					externalUrl: json.data.iam[0].externalUrl,
					ipAddress: json.data.fqdn.ipAddress,
					dataVolumeSize: json.data.size.size,
					dataVolumeUsed: json.data.size.used,
					dataVolumeAvailable: json.data.size.available,
					cpus: json.data.cpus,
					checkPitrEnabled: json.data.checkPitrEnabled,
					checkDatabaseBackup: json.data.checkDatabaseBackup,
				});
			}
		});
	}
	
	// Event handlers
	handleChangeSelectedTab(event, value) {
		this.props.context.state["_AdminTool"].selectedTab = value;
		this.setState({
			// No se utiliza, pero sirve para forzar el refresco de la pantalla
			selectedTab: value,
		});
	}
	
	handleDownloadBackupClick(event) {
		this.props.context.showActivityIndicator();
		let filename = null;
		fetch(this.props.context.baseUrl + "/backup", {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}

	handleExcelExportClick(event) {
		this.props.context.showActivityIndicator();
		let filename = null;
		
		fetch(this.props.context.baseUrl + "/model-export", {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}

    handleDslExportClick(event) {
        this.props.context.showActivityIndicator();
        let filename = null;
        
        fetch(this.props.context.baseUrl + "/model-dsl-export", {
            method: "GET",
            headers: {
                "Authorization": "Basic " + this.props.context.accessToken,
            },
        })
        .then(response => {
            filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
            return response.blob();
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
            this.props.context.hideActivityIndicator();
        });
    }

	handleChangeFQDNClick(event) {
		this.setState({
			updateFQDNDialogOpened: true,
		});
	}

	handleChangeEmbeddingSecretKeyClick(event) {
		this.setState({
			updateEmbeddingSecretKeyDialogOpened: true,
		});
	}
	
	handleChangeDocumentMaxFileSizeMBClick(event) {
		this.setState({
			updateDocumentMaxFileSizeMBDialogOpened: true,
		});
	}
	
	handleChangeIAMClick(event) {
		this.setState({
			updateIAMDialogOpened: true,
		});
	}
	
	handlePitrBackupClick(event) {
		this.setState({
			pitrBackupConfirmationDialogOpened: true,
		});
	}
	
	handlePitrDeletePreviousClick(event) {
		this.setState({
			pitrDeletePreviousConfirmationDialogOpened: true,
		});
	}
	
	handlePitrExportClick(event) {
		this.props.context.showActivityIndicator();

		let filename = null;
		
		fetch(this.props.context.baseUrl + "/backup-export", {
			method: "GET",
			headers: {
				"Authorization": "Basic " + this.props.context.accessToken,
			},
		})
		.then(response => {
			filename = response.headers.get("Content-Disposition").split(";")[1].split("=")[1].slice(1, -1);
			return response.blob();
		})
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();    
			a.remove();  //afterwards we remove the element again         
			this.props.context.hideActivityIndicator();
		});
	}
    
	handleRestoreDatabaseClick(event) {
		this.setState({
			restoreDatabaseConfirmationDialogOpened: true,
		});
	}
	
	handleDeleteDatabaseClick(event) {
		this.setState({
			deleteDatabaseConfirmationDialogOpened: true,
		});
	}

	handleRestartPlatformClick(event) {
		this.setState({
			restartPlatformConfirmationDialogOpened: true,
		});
	}

	handleChangeFQDN() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ setFQDN(fqdn: \"" + this.state.fqdn + "\") }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json.errors != null) {
				document.location = '/login';
			}
			else {
				document.location = "https://" + this.state.fqdn;
			}
		});
	}
	
	handlePitrBackup() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ backupDatabase }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			this.setState({
				pitrBackupConfirmationDialogOpened: false,
				message: this.props.t('pitrBackupSuccess'),
				messageError: false,
				messageOpened: true,
			});
			this.props.context.hideActivityIndicator();
		});
	}
	
	handlePitrDeletePrevious() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ deletePreviousDatabaseBackups }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			this.setState({
				pitrDeletePreviousConfirmationDialogOpened: false,
				message: this.props.t('pitrDeletePreviousSuccess'),
				messageError: false,
				messageOpened: true,
			});
			this.props.context.hideActivityIndicator();
		});
	}
	
	handleRestoreDatabase() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ restoreDatabase(targetTime: \"" + this.state.pitrRestoreTargetTime + "\") }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			this.setState({
				restoreDatabaseConfirmationDialogOpened: false,
				message: this.props.t('pitrRestoreSuccess'),
				messageError: false,
				messageOpened: true,
			});
			document.location = '/login';
		});
	}
	
	handleChangeEmbeddingSecretKey() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ setMetabaseConfiguration(embeddingSecretKey: \"" + this.state.embeddingSecretKey + "\") }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			this.setState({
				updateEmbeddingSecretKeyDialogOpened: false,
				message: this.props.t('updateEmbeddingSecretKeySuccess'),
				messageError: false,
				messageOpened: true,
			});
			this.props.context.hideActivityIndicator();
		});
	}
	
	handleChangeDocumentMaxFileSizeMB() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ setSystemConfiguration(documentMaxFileSizeMB: " + this.state.documentMaxFileSizeMB + ") }", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json != null
					&& json.errors != null
					&& json.errors[0] != null) {
				this.setState({
					updateDocumentMaxFileSizeMBDialogOpened: false,
					message: this.props.t('updateDocumentMaxFileSizeMBError'),
					messageError: true,
					messageOpened: true,
				});
			}
			else {
				this.setState({
					updateDocumentMaxFileSizeMBDialogOpened: false,
					message: this.props.t('updateDocumentMaxFileSizeMBSuccess'),
					messageError: false,
					messageOpened: true,
				});
			}
			this.props.context.hideActivityIndicator();
		});
	}
	
	handleChangeIAM() {
		this.props.context.showActivityIndicator();
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		
		let request = JSON.stringify({query: "{ setIAMConfiguration" + (this.state.publicKey != null || this.state.defaultRole != null || this.state.externalUrl != null ? "(" : "") + (this.state.publicKey != null ? "publicKey: \"" + this.state.publicKey + "\"" : "") + (this.state.defaultRole != null ? "defaultRole: " + this.state.defaultRole : "") + (this.state.externalUrl != null ? "externalUrl: \"" + this.state.externalUrl + "\"" : "") + (this.state.publicKey != null || this.state.defaultRole != null || this.state.externalUrl != null ? ")" : "") + "}", variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			this.setState({
				updateIAMDialogOpened: false,
				message: this.props.t('updateIAMSuccess'),
				messageError: false,
				messageOpened: true,
			});
			this.props.context.hideActivityIndicator();
		});
	}
	
	handleFileDrop(files) {
		if (files != null && files.length == 1) {
			this.setState({
				file: files[0],
				restoreConfirmationDialogOpened: true,
			});
		}
		else {
			this.setState({
				message: this.props.t('restoreOnlyOneFile'),
				messageError: true,
				messageOpened: true,
			});
		}
	}
	
	handleFileDropImportExcel(files) {
		if (files != null && files.length == 1) {
			this.setState({
				file: files[0],
				importExcelConfirmationDialogOpened: true,
			});
		}
		else {
			this.setState({
				message: this.props.t('restoreOnlyOneFile'),
				messageError: true,
				messageOpened: true,
			});
		}
	}

    handleFileDropRestoreImage(files) {
        if (files != null && files.length == 1) {
            if (files[0].name.endsWith(".bin")) {
                this.setState({
                    file: files[0],
                    restoreImageConfirmationDialogOpened: true,
                });
            }
            else {
                this.setState({
                    message: this.props.t('restoreCorrectFile'),
                    messageError: true,
                    messageOpened: true,
                });
            }
        }
        else {
            this.setState({
                message: this.props.t('restoreOnlyOneFile'),
                messageError: true,
                messageOpened: true,
            });
        }
    }
    	
	handleDeleteDatabase() {
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		let thisInstance = this;
		
		request.onloadend = function() {
			thisInstance.setState({
				message: thisInstance.props.t('deleteDatabaseSuccess'),
				messageError: false,
				messageOpened: true,
				deleteDatabaseConfirmationDialogOpened: false,
			});
			thisInstance.props.context.hideActivityIndicator();
			document.location = "/login";
		}
		
		request.open("POST", this.props.context.baseUrl + "/deleteDatabase");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
		
		request.send(this.state.file);
	}
	
	handleRestartPlatform() {
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		let thisInstance = this;
		
		request.onloadend = function() {
			thisInstance.setState({
				message: thisInstance.props.t('restartPlatformSuccess'),
				messageError: false,
				messageOpened: true,
				restartPlatformConfirmationDialogOpened: false,
			});
			thisInstance.props.context.hideActivityIndicator();
			document.location = "/login";
		}
		
		request.open("POST", this.props.context.baseUrl + "/restartPlatform");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
		
		request.send(this.state.file);
	}
	
	handleRestore() {
		let thisInstance = this;
		
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		request.upload.onprogress = function(event) {
			if (event.lengthComputable) {
				thisInstance.setState({
					message: Math.round(100 * event.loaded / event.total) + "%",
					messageError: false,
					messageOpened: true,
				});
			}
		};
		
		request.onloadend = function() {
			thisInstance.setState({
				message: thisInstance.props.t('restoreSuccess'),
				messageError: false,
				messageOpened: true,
				restoreConfirmationDialogOpened: false,
			});
			thisInstance.props.context.hideActivityIndicator();
			document.location = "/login";
		}
		
		request.open("POST", this.props.context.baseUrl + "/restore");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
		
		request.send(this.state.file);
	}
	
	handleImportExcel() {
		let thisInstance = this;
		
		this.props.context.showActivityIndicator();
		
		var request = new XMLHttpRequest();
		
		request.upload.onprogress = function(event) {
			if (event.lengthComputable) {
				thisInstance.setState({
					message: Math.round(100 * event.loaded / event.total) + "%",
					messageError: false,
					messageOpened: true,
				});
			}
		};
		
		request.onloadend = function() {
			thisInstance.props.context.hideActivityIndicator();
			
			if (request.status == 200) {
				thisInstance.setState({
					message: thisInstance.props.t('importSuccess'),
					messageError: false,
					messageOpened: true,
					importExcelConfirmationDialogOpened: false,
				}, () => {
					document.location = "/login";
				});
			}
			else if (request.responseText != null) {
				let error = JSON.parse(request.responseText);
				thisInstance.setState({
					message: error.exceptionMessage + (error.entitySchema != null ? " (" + error.entitySchema + "." + error.entityName + (error.attributeName != null ? "." + error.attributeName : "") + ")" : "") + (error.lineNumber != null ? " [" + error.lineNumber + "]": ""),
					messageError: true,
					messageOpened: true,
					importExcelConfirmationDialogOpened: false,
				});
			}
			else {
				thisInstance.setState({
					message: thisInstance.props.t('error'),
					messageError: true,
					messageOpened: true,
					importExcelConfirmationDialogOpened: false,
				});
			}
		}
		
		request.open("POST", this.props.context.baseUrl + "/model-import");
		request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
		request.send(this.state.file);
	}
	
    handleRestoreImage() {
        let thisInstance = this;
        
        this.props.context.showActivityIndicator();
        
        var request = new XMLHttpRequest();
        
        request.upload.onprogress = function(event) {
            if (event.lengthComputable) {
                thisInstance.setState({
                    message: Math.round(100 * event.loaded / event.total) + "%",
                    messageError: false,
                    messageOpened: true,
                });
            }
        };
        
        request.onloadend = function() {
            thisInstance.props.context.hideActivityIndicator();
            
            if (request.status == 200) {
                thisInstance.setState({
                    message: thisInstance.props.t('importSuccess'),
                    messageError: false,
                    messageOpened: true,
                    importExcelConfirmationDialogOpened: false,
                }, () => {
                    document.location = "/login";
                });
            }
            else if (request.responseText != null) {
                let error = JSON.parse(request.responseText);
                thisInstance.setState({
                    message: error.exceptionMessage + (error.entitySchema != null ? " (" + error.entitySchema + "." + error.entityName + (error.attributeName != null ? "." + error.attributeName : "") + ")" : "") + (error.lineNumber != null ? " [" + error.lineNumber + "]": ""),
                    messageError: true,
                    messageOpened: true,
                    importExcelConfirmationDialogOpened: false,
                });
            }
            else {
                thisInstance.setState({
                    message: thisInstance.props.t('error'),
                    messageError: true,
                    messageOpened: true,
                    importExcelConfirmationDialogOpened: false,
                });
            }
        }
        
        request.open("POST", this.props.context.baseUrl + "/image-restore");
        request.setRequestHeader("Authorization", "Basic " + this.props.context.accessToken);
        request.send(this.state.file);
    }

	// Render
	
	render() {
		// console.log(">> AdminTools.render");
        this.props.context["lastSelectedMenu"] = "_admin";
		
		const { classes, t } = this.props;
		
		let model = this.props.context.model;
		
		if (this.props.context.state["_AdminTool"].selectedTab == null) {
			this.props.context.state["_AdminTool"].selectedTab = 1;
		}
		const selectedTab = this.props.context.state["_AdminTool"].selectedTab;
		
		return model !== null && (
			<>
				<Paper square elevation={1}>
					<div>
						<Toolbar className={classes.toolbar}>
							<Typography variant="h6" className={classes.title} color="inherit" noWrap>{t('admin')}</Typography>
						</Toolbar>
					</div>
					<div className={classes.root}>
						<Grid container spacing={24}>

							<Grid item xs={12}>
								<Tabs value={selectedTab} onChange={this.handleChangeSelectedTab}>
									<Tab value={1} key={1} label={t("systemTab")}/>
									<Tab value={2} key={2} label={t("exportImportTab")}/>
									<Tab value={3} key={3} label={t("domainTab")}/>
									<Tab value={4} key={4} label={t("iamTab")}/>
									<Tab value={5} key={5} label={t("dashboardsTab")}/>
									<Tab value={6} key={6} label={t("databaseTab")}/>
									<Tab value={7} key={7} label={t("otherTab")}/>
								</Tabs>
							</Grid>
						
							{/*
							<Grid item xs={12}>
								<Typography variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('backupBackup')}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Button variant="outlined" onClick={this.handleDownloadBackupClick}>{t('downloadBackup')}</Button>
							</Grid>
							
							<Grid item xs={12}>
								<Typography variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('backupRestore')}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12}>
								<Dropzone
										accept={"application/sql"}
										className={classes.dropZone}
										acceptClassName={classes.stripes}
										rejectClassName={classes.rejectStripes}
										onDrop={this.handleFileDrop}
								>
									<div className={classes.dropzoneTextStyle}>
										<Typography variant="button" 
											className={classes.dropzoneParagraph}
											color="inherit"
											noWrap
										>
											{this.props.t('uploadBackup')}
										</Typography>
									</div>
								</Dropzone>
							</Grid>
							*/}
							
							<Grid item xs={12} className={selectedTab == 2 ? '' : classes.hidden}>
								<Typography 
										style={{paddingBottom: 8}}
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('excelExport')}
								</Typography>
								<Button variant="outlined" onClick={this.handleExcelExportClick}>{t('downloadExcel')}</Button>
							</Grid>
							
                            <Grid item xs={12} className={selectedTab == 2 ? '' : classes.hidden}>
                                <Typography 
                                        style={{paddingBottom: 8}}
                                        variant="subtitle2" 
                                        color="inherit" 
                                        noWrap>
                                    {t('dslExport')}
                                </Typography>
                                <Button variant="outlined" onClick={this.handleDslExportClick}>{t('downloadExcel')}</Button>
                            </Grid>

							<Grid item xs={12} className={selectedTab == 2 ? '' : classes.hidden}>
								<Typography 
										style={{paddingBottom: 8}}
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('excelImport')}
								</Typography>
								<Dropzone
										accept={"application/vnd.ms-excel"} //,image/*"}
										className={classes.dropZone}
										acceptClassName={classes.stripes}
										rejectClassName={classes.rejectStripes}
										onDrop={this.handleFileDropImportExcel}
								>
									<div className={classes.dropzoneTextStyle}>
										<Typography variant="button" 
											className={classes.dropzoneParagraph}
											color="inherit"
											noWrap
										>
											{this.props.t('uploadExcel')}
										</Typography>
									</div>
								</Dropzone>
							</Grid>
							
                            <Grid item xs={12} className={selectedTab == 2 ? '' : classes.hidden}>
                                <Typography 
                                        style={{paddingBottom: 8}}
                                        variant="subtitle2" 
                                        color="inherit" 
                                        noWrap>
                                    {t('environmentCloning')}
                                </Typography>
                                <Button variant="outlined" onClick={this.handlePitrExportClick}>{t('pitrDownload')}</Button>
                            </Grid>

                            <Grid item xs={12} className={selectedTab == 2 ? '' : classes.hidden}>
                                <Typography 
                                        style={{paddingBottom: 8}}
                                        variant="subtitle2" 
                                        color="inherit" 
                                        noWrap>
                                    {t('imageUploadAndRestore')}
                                </Typography>
                                <Dropzone
                                        className={classes.dropZone}
                                        acceptClassName={classes.stripes}
                                        rejectClassName={classes.rejectStripes}
                                        onDrop={this.handleFileDropRestoreImage}
                                >
                                    <div className={classes.dropzoneTextStyle}>
                                        <Typography variant="button" 
                                            className={classes.dropzoneParagraph}
                                            color="inherit"
                                            noWrap
                                        >
                                            {this.props.t('uploadImage')}
                                        </Typography>
                                    </div>
                                </Dropzone>
                            </Grid>
                            
							<Grid item xs={12} className={selectedTab == 3 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('updateFQDN')}
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={6} className={selectedTab == 3 ? '' : classes.hidden}>
								<TextField
										label={t('fqdn')} 
										fullWidth
										inputProps={{
											maxLength: 200, 
										}}
										InputLabelProps={{shrink: true}}
										value={this.state.fqdn}
										onChange={event => this.setState({fqdn: event.target.value})}
								/>
							</Grid>

							<Grid item xs={12} className={selectedTab == 3 ? '' : classes.hidden}>
								<Button variant="outlined" onClick={this.handleChangeFQDNClick}>{t('changeFQDN')}</Button>
							</Grid>
								
							<Grid item xs={12} className={selectedTab == 5 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('updateEmbeddingSecretKey')}
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={6} className={selectedTab == 5 ? '' : classes.hidden}>
								<TextField
										label={t('embeddingSecretKey')} 
										fullWidth
										inputProps={{
											maxLength: 200, 
										}}
										InputLabelProps={{shrink: true}}
										value={this.state.embeddingSecretKey}
										onChange={event => this.setState({embeddingSecretKey: event.target.value})}
								/>
							</Grid>
								
							<Grid item xs={12} className={selectedTab == 5 ? '' : classes.hidden}>
								<Button variant="outlined" onClick={this.handleChangeEmbeddingSecretKeyClick}>{t('changeEmbeddingSecretKey')}</Button>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 7 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('updateDocumentMaxFileSizeMB')}
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={6} className={selectedTab == 7 ? '' : classes.hidden}>
								<TextField
										label={t('documentMaxFileSizeMB')} 
										fullWidth
										inputProps={{
											maxLength: 200, 
										}}
										InputLabelProps={{shrink: true}}
										value={this.state.documentMaxFileSizeMB}
										onChange={event => this.setState({documentMaxFileSizeMB: event.target.value})}
								/>
							</Grid>
								
							<Grid item xs={12} className={selectedTab == 7 ? '' : classes.hidden}>
								<Button variant="outlined" onClick={this.handleChangeDocumentMaxFileSizeMBClick}>{t('changeDocumentMaxFileSizeMB')}</Button>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 4 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('updateIAM')}
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={6} className={selectedTab == 4 ? '' : classes.hidden}>
								<TextField
										label={t('publicKey')} 
										fullWidth
										inputProps={{
											maxLength: 1000, 
										}}
										InputLabelProps={{shrink: true}}
										value={this.state.publicKey}
										onChange={event => this.setState({publicKey: event.target.value})}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} style={{paddingTop: "8px"}} className={selectedTab == 4 ? '' : classes.hidden}>
								<AutoComplete
										label={t('defaultRole')} 
										entityName="Models.Role"
										onChange={item => this.setState({defaultRole: (item != null ? item.value : null)})}
										asyncRef={this.defaultRoleInputRef}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} className={selectedTab == 4 ? '' : classes.hidden}>
								<TextField
										label={t('externalUrl')} 
										fullWidth
										inputProps={{
											maxLength: 1000, 
										}}
										InputLabelProps={{shrink: true}}
										value={this.state.externalUrl}
										onChange={event => this.setState({externalUrl: event.target.value})}
								/>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 4 ? '' : classes.hidden}>
								<Button variant="outlined" onClick={this.handleChangeIAMClick}>{t('changeIAM')}</Button>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 6 ? '' : classes.hidden}>
								<Typography 
										style={{paddingBottom: 8}}
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('backup')}
								</Typography>
								<Button variant="outlined" className={classes.warning} onClick={this.handlePitrBackupClick} disabled={!this.state.checkPitrEnabled}>{t('pitrBackup')}</Button>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 6 ? '' : classes.hidden}>
								<Typography 
										style={{paddingBottom: 8}}
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('pitrDeletePrevious')}
								</Typography>
								<Button variant="outlined" className={classes.warning} onClick={this.handlePitrDeletePreviousClick} disabled={!this.state.checkPitrEnabled}>{t('pitrDeletePrevious')}</Button>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 6 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('pitrRestore')}
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={6} className={selectedTab == 6 ? '' : classes.hidden}>
								<TextField
										label={t('pitrRestoreTargetTime')} 
										fullWidth
										type="datetime-local"
										inputProps={{
											"step": 1,
										}}
										InputLabelProps={{shrink: true}}
										value={this.state.pitrRestoreTargetTime}
										onChange={event => this.setState({pitrRestoreTargetTime: event.target.value})}
										disabled={!this.state.checkPitrEnabled || !this.state.checkDatabaseBackup}
								/>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 6 ? '' : classes.hidden}>
								<Button variant="outlined" color="secondary" className={classes.deleteDatabase} onClick={this.handleRestoreDatabaseClick} disabled={!this.state.checkPitrEnabled || !this.state.checkDatabaseBackup}>{t('pitrRestore')}</Button>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 6 ? '' : classes.hidden}>
								<Typography 
										style={{paddingBottom: 8}}
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('deleteDatabase')}
								</Typography>
								<Button variant="outlined" color="secondary" className={classes.deleteDatabase} onClick={this.handleDeleteDatabaseClick}>{t('deleteRestoreToDefault')}</Button>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 1 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('version')}
								</Typography>
								<Typography variant="subtitle1" 
										color="inherit" 
										noWrap>
									{this.state.version}
								</Typography>
								<Typography variant="subtitle1" 
										color="inherit" 
										noWrap>
									{t('edition') + ": " + model.edition}
								</Typography>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 1 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('cpus')}
								</Typography>
								<Typography variant="subtitle1" 
										color="inherit" 
										noWrap>
									{(this.state.cpus != null ? this.state.cpus : t('unknown'))}
								</Typography>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 1 ? '' : classes.hidden}>
								<Typography 
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('dataVolumeSizes')}
								</Typography>
								<Typography variant="subtitle1" 
										color="inherit" 
										noWrap>
									{t('dataVolumeSize') + ": " + (this.state.dataVolumeSize != null ? this.state.dataVolumeSize : t('unknown')) + ", " + t('dataVolumeUsed') + ": " + (this.state.dataVolumeUsed != null ? this.state.dataVolumeUsed : t('unknown')) + ", " + t('dataVolumeAvailable') + ": " + (this.state.dataVolumeAvailable != null ? this.state.dataVolumeAvailable : t('unknown'))		}
								</Typography>
							</Grid>
							
							<Grid item xs={12} className={selectedTab == 1 ? '' : classes.hidden}>
								<Typography 
										style={{paddingBottom: 8}}
										variant="subtitle2" 
										color="inherit" 
										noWrap>
									{t('restartPlatform')}
								</Typography>
								<Button variant="outlined" color="secondary" className={classes.warning} onClick={this.handleRestartPlatformClick}>{t('restartPlatform')}</Button>
							</Grid>
						</Grid>
					</div>
				</Paper>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.updateFQDNDialogOpened}
						onClose={event => this.setState({updateFQDNDialogOpened: false})}>
					<DialogTitle>{t('updateFQDN')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('confirmFQDNChange')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({updateFQDNDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleChangeFQDN}>{t('changeFQDN')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.updateEmbeddingSecretKeyDialogOpened}
						onClose={event => this.setState({updateEmbeddingSecretKeyDialogOpened: false})}>
					<DialogTitle>{t('updateEmbeddingSecretKey')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('confirmEmbeddingSecretKeyChange')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({updateEmbeddingSecretKeyDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleChangeEmbeddingSecretKey}>{t('changeEmbeddingSecretKey')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.updateDocumentMaxFileSizeMBDialogOpened}
						onClose={event => this.setState({updateDocumentMaxFileSizeMBDialogOpened: false})}>
					<DialogTitle>{t('updateDocumentMaxFileSizeMB')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('confirmDocumentMaxFileSizeMBChange')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({updateDocumentMaxFileSizeMBDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleChangeDocumentMaxFileSizeMB}>{t('changeDocumentMaxFileSizeMB')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.updateIAMDialogOpened}
						onClose={event => this.setState({updateIAMDialogOpened: false})}>
					<DialogTitle>{t('updateIAM')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('confirmIAMChange')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({updateIAMDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleChangeIAM}>{t('changeIAM')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.restoreConfirmationDialogOpened}
						onClose={event => this.setState({restoreConfirmationDialogOpened: false})}>
					<DialogTitle>{t('restoreConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('restoreQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({restoreConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleRestore}>{t('restore')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.importExcelConfirmationDialogOpened}
						onClose={event => this.setState({importExcelConfirmationDialogOpened: false})}>
					<DialogTitle>{t('importConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('importQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({importExcelConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleImportExcel}>{t('import')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.pitrBackupConfirmationDialogOpened}
						onClose={event => this.setState({pitrBackupConfirmationDialogOpened: false})}>
					<DialogTitle>{t('pitrBackupConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('pitrBackupQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({pitrBackupConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="secondary" className={classes.deleteDatabase} onClick={this.handlePitrBackup}>{t('pitrBackup')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.pitrDeletePreviousConfirmationDialogOpened}
						onClose={event => this.setState({pitrDeletePreviousConfirmationDialogOpened: false})}>
					<DialogTitle>{t('pitrDeletePreviousConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('pitrDeletePreviousQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({pitrDeletePreviousConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="secondary" className={classes.deleteDatabase} onClick={this.handlePitrDeletePrevious}>{t('pitrDeletePrevious')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.restoreDatabaseConfirmationDialogOpened}
						onClose={event => this.setState({restoreDatabaseConfirmationDialogOpened: false})}>
					<DialogTitle>{t('pitrRestoreConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('pitrRestoreQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({restoreDatabaseConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="secondary" className={classes.deleteDatabase} onClick={this.handleRestoreDatabase}>{t('pitrRestore')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.deleteDatabaseConfirmationDialogOpened}
						onClose={event => this.setState({deleteDatabaseConfirmationDialogOpened: false})}>
					<DialogTitle>{t('deleteDatabaseConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('deleteDatabaseQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({deleteDatabaseConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="secondary" className={classes.deleteDatabase} onClick={this.handleDeleteDatabase}>{t('deleteDatabase')}</Button>
					</DialogActions>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.restartPlatformConfirmationDialogOpened}
						onClose={event => this.setState({restartPlatformConfirmationDialogOpened: false})}>
					<DialogTitle>{t('restartPlatformConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('restartPlatformQuestion')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({restartPlatformConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button color="secondary" className={classes.warning} onClick={this.handleRestartPlatform}>{t('restartPlatform')}</Button>
					</DialogActions>
				</Dialog>
                <Dialog style={{zIndex: 10000}}
                        open={this.state && this.state.restoreImageConfirmationDialogOpened}
                        onClose={event => this.setState({restoreImageConfirmationDialogOpened: false})}>
                    <DialogTitle>{t('restoreImageConfirmation')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('restoreImageQuestion')}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={event => this.setState({restoreImageConfirmationDialogOpened: false})}>{t('cancel')}</Button>
                        &nbsp;
                        <Button color="secondary" className={classes.deleteDatabase} onClick={this.handleRestoreImage}>{t('restore')}</Button>
                    </DialogActions>
                </Dialog>
				<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						autoHideDuration={5000}
						onClose={event => this.setState({ messageOpened: false })}
						open={this.state && this.state.messageOpened}>
					<SnackbarContent
							className={this.state.messageError ? classes.snackbarError : classes.snackbar}
							message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
					/>
				</Snackbar>
			</>
		);
	}
}

AdminTools.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(AdminTools)));
